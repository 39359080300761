import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useNavigate } from "react-router-dom"
import { useDropzone } from 'react-dropzone'

import Box from '@mui/material/Box';
//import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
// import FormHelperText from '@mui/material/FormHelperText';
import InputAdornmentMui from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import FilledInput from '@mui/material/FilledInput';
import SelectMui from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faDice, faCheck, faPaperPlane, faFileCircleCheck, faFileArrowUp, faFileSlash, faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import { Typography, CircularProgress, FormControlLabel } from '@mui/material';
import CheckboxMui from '@mui/material/Checkbox';
import AutocompleteMui from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ChromePicker } from 'react-color'
import RadioGroup from '@mui/material/RadioGroup'
import RadioMui from '@mui/material/Radio'

import APICtx from './api'
import { TplConfCtx } from './Template'
import StatusIcon from './StatusIcon'
import Button from './Button'
import { FormCtx, useData, useSetData, useErrors, useCallFunction, mkCallPath, ErrorHelper } from './FormLib'

function IconStatus({ syncing, error, untouched=false }) {
	if(syncing)
		return <StatusIcon level="syncing" />
	else if(error)
		return <StatusIcon level="danger" />
	else if(!untouched)
		return <StatusIcon level="success" />
	else
		return null
}

export default function Form({
	data: dataExt,
	setData: setDataExt,
	callGet: callGetRaw,
	callSet: callSetRaw,
	children,
	idRedirect,
	extraData={},
	sx={},
	onLoad,
	onUpdate,
	onSubmit,
	onSave,
	submitLabel='Salva',
	submitIcon,
	submitAndClearLabel,
	_id,
}) {
	// const api = useContext(APICtx)
	const tplConf = useContext(TplConfCtx)
	const navigate = useNavigate()
	// const [ defaults, setDefaults ] = useState(null)
	const [ dataInt, setDataInt ] = useState(null)
	const [ errors, setErrors ] = useState({})
	const [ saveDone, setSaveDone ] = useState(false)
	// const submitRef = useRef(null)

	const data = (dataExt && typeof(dataExt)==='object') ? dataExt : dataInt
	const setData = newData => {
		if(setDataExt && typeof(setDataExt)==='function') {
			const isNew = !newData || !Object.keys(newData).length
			return setDataExt(newData, { isNew })
		}
		else
			return setDataInt(newData)
	}

	const isReady = data!==null
	const callGet = useCallFunction(callGetRaw)
	const callSet = useCallFunction(callSetRaw)

	const handleRefresh = () => {
		if(callGet)
			callGet({ ...extraData, _id }).then(payload => {
				setData(payload.data)
				onLoad?.(payload.data)
			})
		else {
			setData({})
			onLoad?.({})
		}
	}
	const handleSet = async ({ resetAfterSubmit=false } = {}) => {
		const submitData = { ...extraData, ...data }
		if(_id)
			submitData._id = _id

		let ret
		if(onSubmit)
			ret = await onSubmit(submitData)
		if(!ret && callSet)
			ret = await callSet(submitData)
		
		// console.log('SAVE', submitData, ret)
		if(ret?.error)
			setErrors(ret.info)
		else if(ret?.data && typeof(ret?.data)!=='string') //DEPRECATED!! old params check method
			setErrors(ret.data)
		else {
			setErrors({})
			await onSave?.(submitData, ret.data)
			if(ret) {
				if(resetAfterSubmit) {
					const pathRedirect = idRedirect && mkCallPath(await idRedirect(), true)
					pathRedirect && navigate(pathRedirect, { replace:false })
					setData({})
				}
				else if(idRedirect) {
					const pathRedirect = idRedirect && mkCallPath(await idRedirect(ret.data), true)
					pathRedirect && navigate(pathRedirect, { replace:true })
				}
				else if(tplConf.backTo && typeof(tplConf.backTo)==='function')
					await tplConf.backTo()
				else if(tplConf.backTo)
					navigate(tplConf.backTo, { replace:false })
			}
			setSaveDone(true)
		}
	}

	const ctx = { data, setData, errors }

	// eslint-disable-next-line
	useEffect(() => { handleRefresh() }, [])
	const handleSubmit = (e) => {
		e.preventDefault()
		// submitRef?.current.children[0].focus()
		// console.log('REF', submitRef, e)
		handleSet()
	}

	const submitIconEval = submitIcon || (saveDone && faCheck)
	return (
		<Box component="form" onSubmit={handleSubmit} sx={{ p:1, ...sx }}>
			<FormCtx.Provider value={ctx}>
				{isReady && children}
			</FormCtx.Provider>
			{ Boolean(callSet || onSubmit) && (
				<Box sx={{ display:"flex", justifyContent:"right" }}>
					<Button label={submitLabel} icon={submitIconEval} onClick={handleSet} />
					{ submitAndClearLabel && (
						<Button sx={{ ml:1 }} label={submitAndClearLabel} icon={faPlus} onClick={() => handleSet({resetAfterSubmit:true})} />
					)}
				</Box>
			)}
		</Box>
	)
}

function InputAdornment({ suffix, isSyncing, isUntouched, isChanged, error }) {
	const adornment = []
	suffix && adornment.push(
		<span key="_suffix">
			{suffix}
		</span>
	)

	if(!isUntouched && !isChanged)
		adornment.push(<IconStatus key="_status" syncing={isSyncing} error={error} />)

	return (
		<InputAdornmentMui position="end">{adornment}</InputAdornmentMui>
	)
}

// function InputRaw({ type, name, required, disabled, label, defaultValue, suffix, multiline, valueSet, valueGet, valueFixer, inputStyle, ...extraProps }) {
function InputRaw({ sx={}, type, name, required, disabled, label, defaultValue, suffix, multiline, value, setValue, valueFixer, inputStyle, onChange }) {
	// const [ value, setValue ] = useState('')
	const [ valueTmp, setValueTmp ] = useState('')
	const [ isUntouched, setUntouched ] = useState(true)
	const [ isChanged, setChanged ] = useState(false)
	const [ isSyncing, setSyncing ] = useState(false)
	// const [ error, setError ] = useState(false)
	// const [ helper, setHelper ] = useState(null)
	const inputRef = useRef(null)
	// const ctx = useContext(FormCtx)

	const error = useErrors(name)

	const handleChange = e => {
		const inputValue = e.target.value
		setUntouched(false)
		setChanged(true)
		setValueTmp(inputValue)

		const newValue = valueFixer ? valueFixer(inputValue) : inputValue
		setValue(newValue)
		onChange?.(newValue)
	}
	const handleFocus = e => {
		inputRef?.current.focus()
	}
	const handleBlur = e => {
		if (e.currentTarget.contains(e.relatedTarget)) {
			inputRef?.current.focus()
		}
		if(isChanged) {
			setChanged(false)
			// setSyncing(true)
			// const newValue = valueFixer ? valueFixer(valueTmp) : valueTmp
			// valueFixer && setValueTmp(newValue)
			// setValue(newValue)
			setValueTmp(value)
			// valueSet(name, newValue).then(({ error, msg }={}) => {
			// valueSet(name, newValue).then(() => {
			// // ctx.set(name, newValue).then(({ error, msg }) => {
			// 	setSyncing(false)
			// 	// setError(error)
			// 	// setHelper(msg)
			// })
		}
	}
	// useEffect(() => {
	// 	if(defaultValue===undefined)
	// 		valueGet && valueGet(name).then(value =>
	// 			setValue(value===undefined ? '' : value)
	// 		)
	// 	else
	// 		setValue(defaultValue)
	// 	// eslint-disable-next-line
	// }, [ defaultValue ])
	useEffect(() => {
		if(!isChanged)
			setValueTmp(value)
	}, [ value ])

	const propsInput = { required, disabled, label, value:valueTmp, error:Boolean(error), type, onChange:handleChange }
	const propsLabel = { required, error:Boolean(error) }
	type==='date' && (propsLabel.shrink=true)
	const adornment = <InputAdornment suffix={suffix} error={Boolean(error)} isSyncing={isSyncing} isUntouched={isUntouched} isChanged={isChanged} />
	if(multiline) {
		const curLinesQty = valueTmp ? valueTmp.split("\n").length : 0
		propsInput.multiline = Boolean(multiline)
		propsInput.rows = curLinesQty < 3 ? 3 : curLinesQty
	}

	const outerSX = { ...sx, mb:1 }

	return (
		<FormControl sx={outerSX} variant="outlined" error={Boolean(error)} onFocus={handleFocus} onBlur={handleBlur} fullWidth>
			<InputLabel {...propsLabel}>{label}</InputLabel>
			{ inputStyle==='filled' ?
				<FilledInput inputRef={inputRef} {...propsInput} endAdornment={adornment} /> :
				<OutlinedInput inputRef={inputRef} {...propsInput} endAdornment={adornment} />
			}
			<ErrorHelper name={name} />
        </FormControl>
	)
}

export function Input(props) {
	const value = useData(props.name)
	const setValue = useSetData(props.name)

	return <InputRaw {...props} type="text" value={value} setValue={setValue} />
}

export const InputMulti = ({ ...props }) => {
	let value = useData(props.name)
	const setValueRaw = useSetData(props.name)

	if(!Array.isArray(value))
		value = ['']
	if(!props.disabled)
		value = value.concat('')
	const setValue = (valueItem, index) => {
		const newValue = [...value]
		newValue.splice(index, 1, valueItem)
		if(!newValue.at(-1).length)
			newValue.splice(-1, 1)
		setValueRaw(newValue)
	}

	return value.map((valueItem, valueIdx) => (
		<InputRaw key={valueIdx} {...props} type="text" value={valueItem} setValue={x => setValue(x, valueIdx)} />
	))
}

function rndPass(lengthRaw) {
	const length = parseInt(lengthRaw)
	//const pwdChars="0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
	const pwdChars="123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz";
	return Array(length).fill(pwdChars).map(x => x[Math.floor(Math.random() * x.length)]).join('')
}

export function Password({ suffix, randomLength=null, ...props }) {
	const [ showPassword, setShowPassword ] = useState(false)
	const [ defaultValue, setDefaultValue ] = useState('')

	const handleRandomize = () => {
		setShowPassword(true)
		setValue(rndPass(randomLength))
	}

	const suffixCtrl = (
		<>
			{suffix}
			<IconButton
				key="_passwordShow"
				aria-label="toggle password visibility"
				onClick={() => setShowPassword(prevState => !prevState)}
				edge={ randomLength ? 'end' : undefined}
			>
				<FontAwesomeIcon icon={ showPassword ? faEye : faEyeSlash } />
			</IconButton>
			{ randomLength && (
				<IconButton
					key="_passwordRnd"
					aria-label="randomize password"
					onClick={handleRandomize}
				>
					<FontAwesomeIcon icon={faDice} />
				</IconButton>
			)}
		</>
	)

	// const ctx = useContext(FormCtx)
	// const valueSet = async (name, value) => ctx.set(name, value)
	// const valueGet = async () => ''
	const value = useData(props.name)
	const setValue = useSetData(props.name)

	// return <InputRaw {...props} type={showPassword ? 'text' : 'password'} valueSet={valueSet} valueGet={valueGet} suffix={suffixCtrl} defaultValue={defaultValue} />
	return <InputRaw {...props} type={showPassword ? 'text' : 'password'} value={value} setValue={setValue} suffix={suffixCtrl} defaultValue={defaultValue} />
}

export function InputNumber({ decimals=2, decimalsSpace=4, onChange, ...props }) {
	const valueRaw = useData(props.name)
	const setValueRaw = useSetData(props.name)
	const multiplier = Math.pow(10, decimalsSpace)
	
	const raw2value = valueIn => Math.round(valueIn * multiplier)
	
	const value = (valueRaw/multiplier).toFixed(decimals)
	//TODO se ho più decimali dei "decimals" li mostro lo stesso
	
	const setValue = value => {
		const calc = Math.round(value * multiplier)
		return setValueRaw(calc)
	}

	const onChangeRaw = value => onChange?.(raw2value(value))
	
	const valueFixer = valueOld => {
		if(!valueOld)
			return ''
		const str = parseFloat(
				valueOld
					.replace(/ /g, '')
					.replace(/,/g, '.')
		).toFixed(decimals)
		//TODO se ho più decimali dei "decimals" li mostro lo stesso
		return str
	}
	return <InputRaw {...props} type="text" valueFixer={valueFixer} value={value} setValue={setValue} onChange={onChangeRaw} />
}
export function InputDate(props) {
	// const ctx = useContext(FormCtx)
	// const valueSet = async (name, value) => ctx.set(name, value)
	// const valueGet = async (name) => ctx.get(name)
	const value = useData(props.name)
	const setValue = useSetData(props.name)

	return <InputRaw {...props} type="date" value={value} setValue={setValue} />
}
export function InputDateTime(props) {
	const value = useData(props.name)
	const setValue = useSetData(props.name)

	let valueISO = ''
	if(value) {
		const valueDate = new Date(value)
		// if(valueDate)
		// 	valueISO = valueDate?.toISOString()?.split('.')?.[0] || ''

		const offset = valueDate.getTimezoneOffset() * 60000; // Offset in milliseconds
        valueISO = new Date(valueDate.getTime() - offset).toISOString().split('.')[0];
	}

	const saveValue = (valueDate) => {
		if(!valueDate)
			setValue(null)
		else {
			const date = new Date(valueDate + 'Z') // Append 'Z' to treat the string as UTC
			const timestamp = date.getTime() + (date.getTimezoneOffset() * 60000)
			setValue(timestamp)
		}
	}

	return <InputRaw {...props} type="datetime-local" value={valueISO} setValue={saveValue} />
	// return <InputRaw {...props} type="datetime-local" value={valueISO} setValue={saveValue} />
}

export function Autocomplete({ name, required, disabled, label, getOptions, defaultValue, suffix, onChange }) {
	const [ options, setOptions ] = useState(null)
	const [ syncing, setSyncing ] = useState(false)
	const [ open, setOpen ] = useState(false)
	// const [ untouched, setUntouched ] = useState(true)
	// const [ value, setValue ] = useState(null)
	// const [ hint, setHint ] = useState(null)
	// const [ error, setError ] = useState(false)
	// const [ helper, setHelper ] = useState(null)
	// const ctx = useContext(FormCtx)
	const [ selectedOpt, setSelectedOpt ] = useState(null)

	const value = useData(name)
	const setValue = useSetData(name)
	const errorMsg = useErrors(name)
	const error = Boolean(errorMsg)

	const opt2value = opt => (typeof(opt)==='object' && opt!==null) ? opt.value : opt
	const opt2label = opt => (typeof(opt)==='object' && opt!==null) ? (opt.label || opt.value) : opt
	// const value2opt = val => {
	// 	let ret = null
	// 	if(Array.isArray(options) && val) {
	// 		const tmp = options.filter(option => option===val || option?.value===val)
	// 		if(tmp.length)
	// 			valueOpt = tmp[0]
	// 	}
	// 	return ret
	// }

	const updateOptions = async hint => {
		setSyncing(true)
		const newOpts = await getOptions(hint)
		setOptions(newOpts)
		setSyncing(false)
	}

	const onOpen = () => {
		setOpen(true)
		if(options===null)
			updateOptions()
	}
	const onClose = () => setOpen(false)

	const handleChange = (event, inputValue) => {
		if(open)
			updateOptions(inputValue)
		else
			setOptions(null)
	}

	const handleSet = (event, option) => {
		const newValue = opt2value(option)
		// console.log('VAL???', option, newValue)
		setValue(newValue)
		setSelectedOpt(option)
		onChange?.(newValue)
		// setUntouched(false)

		// setSyncing(true)
		// ctx.set(name, newValue).then(({ error, msg }={}) => {
		// 	setSyncing(false)
		// 	setError(error)
		// 	setHelper(msg)
		// })
	}
	// useEffect(() => {
	// 	const value = ctx.get(name)
	// 	setValue( (value===undefined ? defaultValue : value) || null)
	// }, [])
	useEffect(() => {
		// const value = ctx.get(name)
		// setValue( (value===undefined ? defaultValue : value) || null)
		// console.log('SSTTT', value, options, value2opt(value))
		// setSelectedOpt(value2opt(value))
		if(opt2value(selectedOpt) !== opt2value(value))
			setSelectedOpt(value)
	}, [ value ])

	// const valueOpt = (options && options.length) ? options[0] : undefined
	// let valueOpt = null
	// if(Array.isArray(options) && value) {
	// 	const tmp = options.filter(option => option===value || option?.value===value)
	// 	if(tmp.length)
	// 		valueOpt = tmp[0]
	// }

	const adornment = <IconStatus syncing={syncing} error={error} untouched={true} />
	const renderInput = params => (
		<TextField
			{...params}
			label={label}
			InputProps={{
				...params.InputProps,
				endAdornment: (
					<>
						{ adornment }
						{ params.InputProps.endAdornment }
					</>
				),
			}}
		/>
	)

	const setup = {
		value: selectedOpt,
		required, disabled,
		open, onOpen, onClose,
		onInputChange: handleChange,
		onChange: handleSet,
		renderInput,
		options: options || [],
		loading: syncing,
		getOptionLabel: opt2label,
		isOptionEqualToValue: (option, cmp) => opt2value(option) === opt2value(cmp),
		filterOptions: x => x,
		noOptionsText: 'Nessuna scelta disponibile',
	}

	return (
		<FormControl sx={{ mb:1 }} variant="outlined" error={error} fullWidth>
			<AutocompleteMui
				fullWidth
				clearOnEscape
				autoHighlight
				{...setup}
			/>
			<ErrorHelper name={name} />
        </FormControl>
	)
}

export function Select({ name, required, disabled, label, options, defaultValue, onChange, addMissingValue, emptyLabel }) {
	// const ctx = useContext(FormCtx)
	// const [ value, setValue ] = useState(null)
	// const [ error, setError ] = useState(false)
	const [ syncing, setSyncing ] = useState(false)
	const [ untouched, setUntouched ] = useState(true)
	// const [ helper, setHelper ] = useState(null)
	const value = useData(name)
	const setValue = useSetData(name)

	const optValid = Boolean(options.filter(option => option.value===value).length)
	// const helper = ctx.errors(name)
	// const error = Boolean(helper)
	const errorMsg = useErrors(name)
	const error = Boolean(errorMsg)

	const handleChange = (e) => {
		const newValue = e.target.value || null
		setUntouched(false)
		setValue(newValue)
		onChange?.(newValue)
		// if(name) {
		// 	setSyncing(true)
		// 	ctx.set(name, newValue).then(() => {
		// 		setSyncing(false)
		// 		// setError(error)
		// 		// setHelper(msg)
		// 		// !error && onChange?.(newValue)
		// 	})
		// }
	}

	if(!value && defaultValue)
		setValue(defaultValue)

	const iconStatus = <IconStatus syncing={syncing} error={error} untouched={untouched} />

	const props = { required, disabled, label, error,
		onChange: handleChange,
		value: (optValid ? value : ( emptyLabel ? null : Boolean(options.length) && options[0].value )) || '',
	}

	let optsShow = options
	if(addMissingValue && !optValid && value)
		optsShow.push({ value })
	const items = optsShow.map((opt, optIdx) => (
		<MenuItem key={optIdx} value={opt.value || ''}>
			<Grid container direction="row" justifyContent="space-between">
				<Grid item>{opt.label || opt.value}</Grid>
				{ opt.value===value && <Grid item>{iconStatus}</Grid> }
			</Grid>
		</MenuItem>
	))

	// useEffect(() => {
	// 	const ret = ctx.get(name)
	// 	setValue(ret===undefined ? (defaultValue || null) : ret)
	// 	// eslint-disable-next-line
	// }, [])
	// useEffect(() => {
	// 	setValueTmp(value)
	// 	// eslint-disable-next-line
	// }, [ value ])

	return (
		<FormControl sx={{ mb:1, minWidth:120 }} disabled={disabled} fullWidth>
			<InputLabel required={required} error={error}>{label}</InputLabel>
			<SelectMui {...props}>
				{ emptyLabel && (
					<MenuItem value="">
						<Grid container direction="row" justifyContent="space-between">
							<Grid item>{emptyLabel}</Grid>
							{ !value && <Grid item>{iconStatus}</Grid> }
						</Grid>
					</MenuItem>
				)}
				{items}
			</SelectMui>
			<ErrorHelper name={name} />
		</FormControl>
	)
}

export function Radio({ name, required, disabled, label, options, onChange }) {
	// const [ syncing, setSyncing ] = useState(false)
	const [ untouched, setUntouched ] = useState(true)
	const value = useData(name)
	const setValue = useSetData(name)

	const optValid = Boolean(options.filter(option => option.value===value).length)
	const errorMsg = useErrors(name)
	const error = Boolean(errorMsg)

	const handleChange = (e) => {
		const newValue = e.target.value || null
		setUntouched(false)
		setValue(newValue)
		onChange?.(newValue)
	}

	// if(!value && defaultValue)
	// 	setValue(defaultValue)

	const iconStatus = <IconStatus error={error} untouched={untouched} />
	const evalValue = optValid ? value : ''

	// const props = { required, disabled, label, error,
	// 	onChange: handleChange,
	// 	value: (optValid ? value : ( Boolean(options.length) && options[0].value )) || '',
	// }

	let optsShow = options
	// const items = optsShow.map((opt, optIdx) => (
	// 	<MenuItem key={optIdx} value={opt.value || ''}>
	// 		<Grid container direction="row" justifyContent="space-between">
	// 			<Grid item>{opt.label || opt.value}</Grid>
	// 			{ opt.value===value && <Grid item>{iconStatus}</Grid> }
	// 		</Grid>
	// 	</MenuItem>
	// ))
	const items = optsShow.map((opt, optIdx) => (
		<FormControlLabel key={optIdx} value={opt.value} control={<RadioMui />} label={opt.label || opt.value} />
	))

	return (
		<FormControl sx={{ mb:1, minWidth:120 }} disabled={disabled} fullWidth>
			{/* <InputLabel required={required} error={error}>{label}</InputLabel>
			<SelectMui {...props}>
				{items}
			</SelectMui> */}

			<FormLabel id="demo-radio-buttons-group-label">{label} {iconStatus}</FormLabel>
			<ErrorHelper name={name} />
			<RadioGroup
				value={evalValue}
				onChange={handleChange}
			>
				{items}
			</RadioGroup>
		</FormControl>
	)
}

export function Checkbox({ label, name, onChange, disabled, required, value=true }) {
	// const ctx = useContext(FormCtx)
	// const [ syncing, setSyncing ] = useState(false)
	// const [ untouched, setUntouched ] = useState(true)
	// const [ isChecked, setChecked ] = useState(name ? ctx.get(name)===value : Boolean(defaultValue))
	// const [ error, setError ] = useState(null)
	// const [ helper, setHelper ] = useState(null)
	const isChecked = useData(name)
	const setChecked = useSetData(name)
	const errorMsg = useErrors(name)
	const error = Boolean(errorMsg)

	// const helper = ctx.errors(name)
	// const error = Boolean(helper)

	const handleChange = (e) => {
		const newValue = e.target.checked ? value : null
		if(name) {
			// setSyncing(true)
			// ctx.set(name, newValue).then(() => {
			// 	setSyncing(false)
			// 	// setError(error)
			// 	// setHelper(msg)
			// 	// !error && onChange?.(newValue)
			// 	// !error && setChecked(newValue)
				setChecked(newValue)
			// })
		}
		onChange?.(newValue)
	}

	// const iconStatus = <IconStatus syncing={syncing} error={error} untouched={untouched} />

	if(required)
		label += '*'
	return (
		<FormControl sx={{ mb:1 }} fullWidth component="fieldset" disabled={disabled} error={error}>
			<FormControlLabel
				checked={Boolean(isChecked)}
				control={<CheckboxMui />}
				label={label}
				onChange={handleChange}
				disabled={Boolean(disabled)}
			/>
			<ErrorHelper name={name} />
		</FormControl>
	)
}

export function ColorPicker({ label, name, onChange, disabled, required, isCore }) {
	const value = useData(name)
	const setValue = useSetData(name)

	const handleChange = ({ hex:valNew }) => {
		onChange?.(valNew, name)
		setValue(valNew)
	}

	if(required)
		label += '*'
	
	return (
		<FormControl sx={{ mb:1 }} fullWidth>
			<Typography>{label}</Typography>
			<ChromePicker color={value} onChangeComplete={handleChange} />
			<ErrorHelper name={name} />
		</FormControl>
	)
}

export function FileUpload({ label, name, tokenEndpoint, disabled, required, acceptFilter }) {
	const api = useContext(APICtx)
	const [ isUploading, setIsUploading ] = useState(false)
	const [ uploadToken, setUploadToken ] = useState(null)

	useEffect(() => {
		api.call(tokenEndpoint).then(setUploadToken)
	}, [])

	const value = useData(name)
	const setValue = useSetData(name)

	const errorMsg = useErrors(name)
	const error = Boolean(errorMsg)

	// const onDrop = useCallback(async acceptedFiles => {
	const onDrop = async acceptedFiles => {
		if(acceptedFiles.length) {
			setIsUploading(true)
			
			const uploadId = await api.storage.upload(uploadToken, acceptedFiles[0])
			setValue(uploadId)
			
			setIsUploading(false)
		}
	}
	// }, [ value, uploadToken ])

	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		onDrop,
		accept: acceptFilter,
		disabled,
		multiple: false,
	})

	const statusIcon = <FontAwesomeIcon
		icon={ isUploading ? faSpinner : (isDragActive ? faFileArrowUp : (value ? faFileCircleCheck : faFileSlash ) ) }
		spin={isUploading}
		size="5x"
	/>

	const dropzoneSX = {
		border: "1px solid black",
		p: 3,
		cursor: disabled ? 'not-allowed' : 'pointer',
		width: '300px',
		textAlign: 'center',
		borderWdth: "2px",
		borderRadius: "2px",
		borderColor: "#aaaaaa",
		borderStyle: "dashed",
		backgroundColor: "#fafafa",
	}

	if(required)
		label += '*'
	return (
		<FormControl sx={{ mb:1 }} fullWidth component="fieldset" disabled={disabled} error={error}>
			<Box sx={dropzoneSX}>
				<div {...getRootProps()}>
					<input {...getInputProps()} />
					{statusIcon}
					{ Boolean(label) && <Typography sx={{ mt:1 }}>{label}</Typography> }
				</div>
			</Box>
			<ErrorHelper name={name} />
		</FormControl>
	)
}